import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Title from "../components/Title";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const NotFoundPage = ({pageContext}) => {
    const {
        breadcrumb: {crumbs},
    } = pageContext

    return (
        <Layout>
            <Metas title="Page non trouvée"/>
            <section className="section page-breadcrumb is-small has-background-light">
                <div className="container">
                    <CustomBreadcrumb crumbs={crumbs}/>
                </div>
            </section>
            <section className="section page-content">
                <div className="container">
                    <Title
                        title="Page non trouvée"
                        className="has-text-centered"
                        typesense={false}
                    />

                    <div className="block content">
                        <p>La page que vous recherchez n'a pas été trouvée.</p>
                    </div>

                    <div className="buttons">
                        <Link to="/" className="button is-primary">Retour à l'accueil</Link>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NotFoundPage
